export const UPDATE_ALL_CAPABILITIES = 'UPDATE_CAPABILITIES';
export const UPDATE_ALL_TRAINING = 'UPDATE_ALL_TRAINING';
export const SELECT_TRAINING_FOR_UPDATE = 'SELECT_TRAINING_FOR_UPDATE';
export const UPDATE_ALL_GOALS = 'UPDATE_ALL_GOALS';
export const SELECT_GOAL_FOR_UPDATE = 'SELECT_GOAL_FOR_UPDATE';
export const UPDATE_GOAL_MILESSTONE = 'UPDATE_GOAL_MILESSTONE';
export const SELECT_GOAL_MILESSTONE_FOR_UPDATE = 'SELECT_GOAL_MILESSTONE_FOR_UPDATE';
export const UPDATE_EMPLOYEE_LIST = 'UPDATE_EMPLOYEE_LIST';
export const UPDATE_EMPLOYEE_SELECTION_LIST = 'UPDATE_EMPLOYEE_SELECTION_LIST';
export const UPDATE_ASSESSEMENT_LIST = 'UPDATE_ASSESSEMENT_LIST';
export const SELECT_ASSESSEMENT_FOR_UPDATE = 'SELECT_ASSESSEMENT_FOR_UPDATE';
export const MY_REVIEW_ASSESSEMENT = 'MY_REVIEW_ASSESSEMENT';
export const UPDATE_MANAGE_PROJECT_LIST = 'UPDATE_MANAGE_PROJECT_LIST';

export const UPDATE_ALL_POSITION = 'UPDATE_ALL_POSITION';
export const UPDATE_ACTIVE_CAPABILITES = 'UPDATE_ACTIVE_CAPABILITES';
export const UPDATE_NOTIFICATION_LIST = 'UPDATE_NOTIFICATION_LIST';
export const EDIT_NOTIFICATION = 'EDIT_NOTIFICATION';



