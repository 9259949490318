import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  FormGroup,
  Input,
  Alert,
  Label,
  ModalFooter,
} from "reactstrap";
import ChooseSubscription from "../pages/SubscriptionPayment";
import { Formik, Form } from "formik";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import axios from "axios";
import AjaxService from "../service/AjaxService";
import { LOGIN_URL, LOGOUT_URL } from "../../src/service/service_const";
import AuthenticationService from "../service/AuthenticationService";
const createSchema = Yup.object().shape({
  email: Yup.string().when("__resetpassword", {
    is: true,
    then: Yup.string()
      .min(5, "The minimum length is 5 for this field")
      .required("Please enter your registered email address"),
  }),
});

class LoginModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chooseSubsKey: false,
      loginData: {},
      orgData: {},
      bearerToken: "",
      show: this.props.show,
      email: "",
      password: "",
      __resetpassword: false,
    };
  }

  componentDidMount() {}
  componentWillUnmount() {
    window.setTimeout(() => {
      sessionStorage.removeItem("USER_KEY");
      sessionStorage.removeItem("ACCESS_TOKEN_KEY");
      window.location.href = "#/login";
    }, 3600000);
  }

  __hide() {
    this.setState({ show: !this.state.show });
  }
  close() {
    this.setState({ show: false });
  }
  open(data) {
    this.setState({
      ...this.state,
      ...data,
      show: !this.state.show,
    });
  }
  email;

  async loginHandler() {
    let paymentSatus = ["Payment_Pending", "free_subscription"];
    const loginData = await AjaxService.makePostRequest(LOGIN_URL, {
      username: this.state.email,
      password: this.state.password,
    });

    if (
      loginData &&
      loginData.status === "Success" &&
      loginData.result &&
      loginData.result.userDetail
    ) {
      // loginData.result.userDetail.roles = [
      //   "MANAGE_EMPLOYEE",
      //   "MANAGE_GOALS",
      //   "MANAGE_TRAINING",
      //   "MANAGE_PROJECT",
      //   "MANAGE_POSITION",
      //   "MANAGE_ASSESSMENT",
      //   "MANAGE_CAPABILITY",
      //   "MANAGE_CONTENT",
      // ]

      if (loginData.result.organizationDetail) {
        let orgDetails = {
          orgId: loginData.result.organizationDetail.orgId,
          userId: loginData.result.userDetail.id,
          status: loginData.result.organizationDetail.status,
          token: loginData.result.bearerToken,
        };
        sessionStorage.setItem("petent-orgDetails", JSON.stringify(orgDetails));
        let statusPayment = loginData.result.organizationDetail.status;
        if (statusPayment && paymentSatus.includes(statusPayment)) {
          this.setState({
            chooseSubsKey: true,
            bearerToken: loginData.result.bearerToken,
            orgData: loginData.result.organizationDetail,
            loginData: loginData.result.userDetail,
          });
        } else {
          AuthenticationService.setToken(loginData.result.bearerToken);
          AuthenticationService.setUser(loginData.result.userDetail);
          this.props.history.push("/pulse/dashboard");
        }
      } else {
        AuthenticationService.setToken(loginData.result.bearerToken);
        AuthenticationService.setUser(loginData.result.userDetail);
        this.props.history.push("/pulse/dashboard");
      }
    }
  }

  handleChange = ({ target }) => {
    this.state[target.name] = target.value;
    this.setState({ ...this.state });
  };

  // registerPage = () => {
  //   <Link to='//registration' />
  // }

  render() {
    return this.state.chooseSubsKey == true ? (
      <ChooseSubscription
        orgData={this.state.orgData}
        loginData={this.state.loginData}
        bearerToken={this.state.bearerToken}
      />
    ) : (
      <Modal isOpen={this.state.show} backdrop={false} className='login_modal'>
        <ModalHeader className='login_header'>Login</ModalHeader>
        <ModalBody>
          <FormGroup className='input_form'>
            <Label for='exampleEmail'>Email</Label>
            <Input
              type='email'
              name='email'
              id='ipEmail'
              onChange={this.handleChange.bind(this)}
              placeholder='Email'
            />
          </FormGroup>
          <FormGroup className='input_form'>
            <Label for='examplePassword'>Password</Label>
            <Input
              type='password'
              name='password'
              id='ipPassword'
              onChange={this.handleChange.bind(this)}
              placeholder='Password'
            />
          </FormGroup>

          <Formik
            enableReinitialize={true}
            initialValues={this.state}
            validationSchema={createSchema}
            onSubmit={(values) => {
              debugger;
              this.props.save(values);
            }}>
            {({ errors, touched, setFieldValue }) => (
              <Form>
                <div className='d-grid gap-2'>
                  <Button
                    color='primary'
                    className='d-block w-100'
                    onClick={this.loginHandler.bind(this)}>
                    Login
                  </Button>
                  <Button
                    color='outline-primary'
                    className='d-block w-100'
                    onClick={this.props.onRegister}>
                    Register Organization
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
        <ModalFooter className='login_footer'>
          <span style={{ textAlign: "left" }}>
            If you dont have account please contact our sales team
          </span>
        </ModalFooter>
      </Modal>
    );
  }
}

export default withRouter(LoginModal);
