import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PUBLIC } from "../../config/configureStore";
import axios from "axios";
import { toast } from "react-toastify";
import AjaxLoader from "../../ui-components/loader";
import AuthenticationService from "../../service/AuthenticationService";
import { useHistory } from "react-router-dom";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
const stripePromise = loadStripe(STRIPE_PUBLIC);

const OneTimePayment = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <WholePaymentComponent {...props} />
    </Elements>
  );
};

const WholePaymentComponent = (props) => {
  let history = useHistory();
  const [emailId, setEmailId] = useState("");
  const stripeData = useStripe();
  const [loading, setLoading] = useState(false);
  const elementsData = useElements();
  const handleSubmitStripePay = async (e) => {
    e.preventDefault();
    try {
      const paymentMethod = await stripeData.createToken(
        elementsData.getElement(CardElement)
      );
      try {
        let orgDetails = JSON.parse(
          sessionStorage.getItem("petent-orgDetails")
        );

        const token = orgDetails.token;
        const headers = {
          "Content-Type": "application/json",
          accept: "application/json",
          "cache-control": "no-cache",
          pragma: "no-cache",
        };

        if (token) {
          headers["Authorization"] = `Bearer ${token}`;
        }
        const payload = {
          amount: "999",
          email: emailId,
          plan: "One-time charge",
          token: paymentMethod.token.id,
          orgId: orgDetails.orgId,
          userId: orgDetails.userId,
        };
        setLoading(true);
        await axios
          .post(
            "https://pulseoftheworkforce.com/services/create-charge",
            payload,
            {
              headers,
            }
          )
          .then((res) => {
            AuthenticationService.setToken(props.bearerToken);
            AuthenticationService.setUser(props.loginData);
            history.push("/pulse/dashboard");
            sessionStorage.removeItem("petent-orgDetails");
          })
          .catch((err) => {
            setLoading(false);
            toast.error("Payment faild try again.");
          });
      } catch (error) {
        toast.error("Please provide a valid card details");
      }
    } catch {
      toast.error("Please provide a valid card details");
    }
  };
  return loading ? (
    <AjaxLoader loadingText="Making your payment.don't go back"></AjaxLoader>
  ) : (
    <div className='bg-light pt-5'>
      <section className='py-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-md-8 col-12 my-auto mx-auto'>
              <h1>Pulse One Time Charge</h1>
              <p className='lead mb-4'>
                Please fill the form below to complete the order payment
              </p>
              <div className='card mb-4'>
                <div className='card-body'>
                  <h5>One Time Charge</h5>
                  <p className='lead'>USD $ 999</p>
                </div>
              </div>
              <form>
                <input id='api-key' type='hidden' />
                <div className='form-group'>
                  <label className='font-weight-medium' htmlFor='card-element'>
                    Enter credit or debit card below
                  </label>
                  <div className='w-100' id='card-element'>
                    <CardElement />
                  </div>
                </div>
                <div className='form-group'>
                  <input
                    className='form-control'
                    id='email'
                    onChange={(e) => {
                      setEmailId(e.target.value);
                    }}
                    name='email'
                    placeholder='Email Address'
                    type='email'
                    required
                  />
                </div>

                <div
                  className='text-danger w-100'
                  id='card-errors'
                  role='alert'></div>
                <div className='form-group pt-2'>
                  <button
                    onClick={(e) => {
                      handleSubmitStripePay(e);
                    }}
                    className='btn btn-primary btn-block'
                    id='submitButton'
                    type='submit'>
                    Pay With Your Card
                  </button>
                  <div className='small text-muted mt-2'>
                    Pay securely with Stripe. By clicking the button above, you
                    agree to our <a>Terms of Service</a>,<a>Privacy </a> and
                    <a> Refund</a> policies.
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OneTimePayment;
