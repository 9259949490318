import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PUBLIC } from "../../config/configureStore";
import axios from "axios";
import { toast } from "react-toastify";
import AjaxLoader from "../../ui-components/loader";
import AuthenticationService from "../../service/AuthenticationService";
import { useHistory } from "react-router-dom";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
const stripePromise = loadStripe(STRIPE_PUBLIC);

const SubscriptionPayComponent = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <SubscriptionPayment {...props} />
    </Elements>
  );
};

const SubscriptionPayment = (props) => {
  let history = useHistory();
  const [planId, setPlanId] = useState("");
  const [emailId, setEmailId] = useState("");
  const [coupon, setCoupon] = useState("");
  const stripeData = useStripe();
  const [loading, setLoading] = useState(false);
  const elementsData = useElements();

  const handleSubmitStripePay = async (e) => {
    e.preventDefault();
    try {
      const paymentMethod = await stripeData.createToken(
        elementsData.getElement(CardElement)
      );
      try {
        let orgDetails = JSON.parse(
          sessionStorage.getItem("petent-orgDetails")
        );

        const token = orgDetails.token;
        const headers = {
          "Content-Type": "application/json",
          accept: "application/json",
          "cache-control": "no-cache",
          pragma: "no-cache",
        };

        if (token) {
          headers["Authorization"] = `Bearer ${token}`;
        }
        const payload = {
          email: emailId,
          plan: planId,
          token: paymentMethod.token.id,
          orgId: orgDetails.orgId,
          coupon: coupon || "",
        };
        setLoading(true);
        await axios
          .post(
            "https://pulseoftheworkforce.com/services/create-subscription",
            payload,
            {
              headers,
            }
          )
          .then((res) => {
            AuthenticationService.setToken(props.bearerToken);
            AuthenticationService.setUser(props.loginData);
            history.push("/pulse/dashboard");
            sessionStorage.removeItem("petent-orgDetails");
          })
          .catch((err) => {
            setLoading(false);
            toast.error("Payment faild try again.");
          });
      } catch (error) {
        toast.error("Please provide a valid card details");
      }
    } catch {
      toast.error("Please provide a valid card details");
    }
  };

  return loading ? (
    <AjaxLoader loadingText="Making your payment.don't go back"></AjaxLoader>
  ) : (
    <section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6 col-md-8 col-12 my-auto mx-auto'>
            <h1>Stripe Recurring Subscription</h1>
            <p className='lead mb-4'>
              Please fill the form below to complete the payment
            </p>
            <h5 className='mb-2'>Choose your payment plan</h5>
            <p className='text-muted'>
              60% OFF when you upgrade to annual plan.
            </p>
            <div
              onChange={(e) => {
                setPlanId(e.target.value);
              }}
              className='py-2'>
              <div className='custom-control custom-radio'>
                <input
                  className='custom-control-input'
                  id='monthly-plan'
                  name='premium-plan'
                  type='radio'
                  value='plan_KRvr6rm4xonjMS'
                />
                <label className='custom-control-label' htmlFor='monthly-plan'>
                  <strong>Monthly $100.00</strong>
                  <br />
                  <small className='text-muted'>
                    Pay $100.00 every month and get access to all premium
                    features.
                  </small>
                </label>
              </div>
              <div className='custom-control custom-radio mt-3'>
                <input
                  className='custom-control-input'
                  id='annually-plan'
                  name='premium-plan'
                  type='radio'
                  value='plan_KYYykVnP1jIWmF'
                />
                <label className='custom-control-label' htmlFor='annually-plan'>
                  <strong>Yearly $800.00</strong>
                  <span className='badge badge-primary ml-1'>60% OFF</span>
                  <br />
                  <small className='text-muted'>
                    Pay $800.00 every year and get access to all premium
                    features.
                  </small>
                </label>
              </div>
            </div>
            <form>
              <input id='api-key' type='hidden' />
              <div className='form-group'>
                <label className='font-weight-medium' htmlFor='card-element'>
                  Enter credit or debit card below
                </label>
                <div className='w-100' id='card-element'>
                  <CardElement />
                </div>
              </div>
              <div className='form-group'>
                <input
                  onChange={(e) => {
                    setEmailId(e.target.value);
                  }}
                  className='form-control'
                  id='email'
                  name='email'
                  placeholder='Email Address'
                  type='email'
                  required
                />
              </div>
              <div className='form-group'>
                <input
                  onChange={(e) => {
                    setCoupon(e.target.value);
                  }}
                  className='form-control'
                  id='coupon'
                  name='coupon'
                  placeholder='Coupon code (optional)'
                  type='text'
                />
              </div>

              <div
                className='text-danger w-100'
                id='card-errors'
                role='alert'></div>
              <div className='form-group pt-2'>
                <button
                  className='btn btn-primary btn-block'
                  id='submitButton'
                  onClick={(e) => {
                    handleSubmitStripePay(e);
                  }}
                  type='submit'>
                  Pay With Your Card
                </button>
                <div className='small text-muted mt-2'>
                  Pay securely with Stripe. By clicking the button above, you
                  agree to our{" "}
                  <a target='_blank' href='#'>
                    Terms of Service
                  </a>
                  ,<a>Privacy</a> and
                  <a>Refund</a> policies.
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SubscriptionPayComponent;
