import React, { Fragment } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { Route, withRouter } from "react-router-dom";

import ResizeDetector from "react-resize-detector";

import AppMain from "../../Layout/AppMain";
import AjaxLoader from "../../ui-components/loader";

import RegistrationPage from "../../pages/registration/registration"
import AuthenticationService from '../../service/AuthenticationService'
import { ToastContainer } from "react-toastify";
import { Suspense } from "react";



class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closedSmallerSidebar: false,
    };
  }

  componentDidMount(){
    setTimeout(AuthenticationService.performLogin,500)
    //AuthenticationService.performLogin()
  }
  render() {
    let {
      colorScheme,
      enableFixedHeader,
      enableFixedSidebar,
      enableFixedFooter,
      enableClosedSidebar,
      closedSmallerSidebar,
      enableMobileMenu,
      enablePageTabsAlt,
    } = this.props;
    
    return (
      <ResizeDetector
       
        render={({ width }) => (
          <Fragment>
            <ToastContainer/>
            { AuthenticationService.is_loggedin() ?
             <div
              className={cx(
                "app-container app-theme-" + colorScheme,
                { "fixed-header": enableFixedHeader },
                { "fixed-sidebar": enableFixedSidebar || width < 1250 },
                { "fixed-footer": enableFixedFooter },
                { "closed-sidebar": enableClosedSidebar || width < 1250 },
                {
                  "closed-sidebar-mobile": closedSmallerSidebar || width < 1250,
                },
                { "sidebar-mobile-open": enableMobileMenu },
                { "body-tabs-shadow-btn": enablePageTabsAlt }
              )}>
              
              <AppMain />
            </div>: AuthenticationService.isLoginInprogress()? (<AjaxLoader loadingText="Authenticating..."></AjaxLoader>):(<RegistrationPage show={!AuthenticationService.isLoginInprogress() && !AuthenticationService.is_loggedin()}></RegistrationPage>)
            }
           
          <Route exact={true} path="/registration" component={RegistrationPage}/>  
          </Fragment>
        )}
      />
    );
  }
}

const mapStateToProp = (state) => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
});

export default withRouter(connect(mapStateToProp)(Main));
