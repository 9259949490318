import React, { useState } from "react";
import OneTimePayment from "./OneTimePay";
import SubscriptionPayment from "./SubscriptionPayment";
import AuthenticationService from "../../service/AuthenticationService";
import { useHistory } from "react-router-dom";

const ChooseSubscription = (props) => {
  let history = useHistory();
  const [choosePayment, setChoosePayment] = useState("defaultHome");
  const HomePage = () => {
    return (
      <div className='bg-light pt-5'>
        <section className='py-5'>
          <div className='container'>
            {props.orgData.status == "free_subscription" ? (
              <div style={{ float: "right" }}>
                <button
                  onClick={() => {
                    AuthenticationService.setToken(props.bearerToken);
                    AuthenticationService.setUser(props.loginData);
                    history.push("/pulse/dashboard");
                  }}
                  className='btn btn-light'>
                  Skip for now
                </button>
              </div>
            ) : null}

            <div className='row'>
              <div className='col-lg-7 col-md-10 col-12 my-auto mx-auto text-center'>
                <h1>Stripe Payment</h1>
                <p className='lead mb-4'>What would you like to do?</p>
                <button
                  onClick={() => setChoosePayment("subscriptionPayement")}
                  className='btn btn-primary mr-4'>
                  Create Recurring Subscription
                </button>
                <button
                  onClick={() => setChoosePayment("oneTime")}
                  className='btn btn-success'>
                  Create One-Time Charge
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  };
  if (choosePayment === "defaultHome") {
    return <HomePage />;
  }
  if (choosePayment === "oneTime") {
    return (
      <OneTimePayment
        orgData={props.orgData}
        loginData={props.loginData}
        bearerToken={props.bearerToken}
      />
    );
  }
  if (choosePayment === "subscriptionPayement") {
    return (
      <SubscriptionPayment
        orgData={props.orgData}
        loginData={props.loginData}
        bearerToken={props.bearerToken}
      />
    );
  }
};

export default ChooseSubscription;
