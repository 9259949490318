import {
  AUTHENTICATION_URL,
  OAUTH_REDIRECT_URL,
  AUTHERIZATION_URL,
  LOGOUT_URL,
} from "./service_const";
import {
  get_value_from_object,
  showErrorMessage,
} from "../ui-components/ui_functions";
import StoreageService from "./StoreageService";
import AjaxService from "./AjaxService";
class OauthService {
  ACCESS_TOKEN_KEY = "ACCESS_TOKEN_KEY";
  USER_KEY = "USER_KEY";
  __token = null;
  constructor() {}
  async processLogin() {
    this.__logout();
    window.history.pushState("", "", "/#/pulse/dashboard");
    window.location.href = this.__login_url();
  }

  isLoggedIn() {
    return StoreageService.get(this.ACCESS_TOKEN_KEY) != null;
  }

  logout() {
    return new Promise((resolve, reject) => {
      this.__logout();
      resolve(true);
    });

    // const params = [];
    // //params.push(this.__make_url_param("response_type","code"))
    // //params.push(this.__make_url_param("client_id",OAUTH_CLIENT_ID))
    // //params.push(this.__make_url_param("scope","openid"))
    // //params.push(this.__make_url_param("redirect_uri",OAUTH_REDIRECT_URL))
    // params.push(this.__make_url_param("redirectURL", OAUTH_REDIRECT_URL));

    // AjaxService.makeGetRequest([LOGOUT_URL, params.join("&")].join("?")).then(
    //   (res) => {
    //     this.__logout();
    //     const logout_url = get_value_from_object(res, "result", null);
    //     window.location.href = logout_url;
    //   }
    // );
  }

  __logout() {
    StoreageService.remove(this.ACCESS_TOKEN_KEY);
    StoreageService.remove(this.USER_KEY);
  }

  setToken(token) {
    if (token) {
      this.__token = token;
      StoreageService.save(this.ACCESS_TOKEN_KEY, token);
    }
  }

  setUser(user) {
    console.log("hgfhgggggggggggg", user);
    if (user.firstName == "Hemanth") user.roles.push("MANAGE_TIMESHEET");
    if (user.firstName == "kumar") user.roles.push("MY_TIMESHEET");
    StoreageService.save(this.USER_KEY, JSON.stringify(user));
  }

  async getAccessToken() {
    const authToken = this.getQueryParam("code");
    if (authToken != null && authToken.length > 0) {
      AjaxService.makePostWithFullURLRequest(AUTHERIZATION_URL, {
        code: authToken,
        redirectURI: OAUTH_REDIRECT_URL,
      }).then((res) => {
        if (get_value_from_object(res, "status") === "Success") {
          debugger;
          const authResponse = get_value_from_object(res, "result", {});
          StoreageService.save(
            this.ACCESS_TOKEN_KEY,
            get_value_from_object(authResponse, "token", null)
          );
          delete authResponse["token"];
          StoreageService.save(this.USER_KEY, JSON.stringify(authResponse));

          // if(get_value_from_object(res.result,'emailId') == 'Timothy.Cowan@Spider-Technology.com'){
          //   window.history.pushState('', '', '/#/pulse/payment/scheme');
          //   window.location.href = '/#/pulse/payment/scheme';
          //   setTimeout(() => {
          //   //  location.reload();
          //   }, 1000);

          // }else{
          window.history.pushState("", "", "/#/pulse/dashboard");
          window.location.href = "";
          //  }

          //   if(get_value_from_object(res.result,'emailId') == 'Timothy.Cowan@Spider-Technology.com'){
          //   window.history.pushState('', '', '/#/pulse/payment/scheme');
          // }
        } else {
          showErrorMessage(
            get_value_from_object(
              res,
              "errorMessage",
              "System encounter some technical issue."
            )
          );
          setTimeout(function () {
            //     window.history.pushState('', '', '/#/pulse/dashboard');
            //     window.location.href = ""
          }, 1000);
        }
      });
    }
  }

  getToken() {
    return StoreageService.get(this.ACCESS_TOKEN_KEY);
  }

  getUser() {
    try {
      return JSON.parse(StoreageService.get(this.USER_KEY));
    } catch (e) {
      return {};
    }
  }

  getQueryParam(key) {
    return decodeURIComponent(
      window.location.search.replace(
        new RegExp(
          "^(?:.*[&\\?]" +
            encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") +
            "(?:\\=([^&]*))?)?.*$",
          "i"
        ),
        "$1"
      )
    );
  }

  __login_url() {
    const params = [];
    //params.push(this.__make_url_param("response_type","code"))
    //params.push(this.__make_url_param("client_id",OAUTH_CLIENT_ID))
    //params.push(this.__make_url_param("scope","openid"))
    //params.push(this.__make_url_param("redirect_uri",OAUTH_REDIRECT_URL))
    params.push(this.__make_url_param("redirectURL", OAUTH_REDIRECT_URL));

    return [AUTHENTICATION_URL, params.join("&")].join("?");
  }

  __access_token_param(authToken) {
    const params = [];
    params.push(this.__make_url_param("grant_type", "authorization_code"));
    params.push(this.__make_url_param("code", authToken));
    params.push(this.__make_url_param("redirect_uri", OAUTH_REDIRECT_URL));
    return params.join("&");
  }

  __make_url_param(key, value) {
    return [key, value].join("=");
  }
}
export default new OauthService();
