import AES from 'crypto-js/aes';
class StorageService {
    APP_PREFIX = "pulse_service";
    APP_SECRETE_KEY = "@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@&&&&&&&&&&&&&&&&&&!!!!!!!!!!!!!!!!!!111111111111111111111111111111111111111111111"

    save(key,data){
        const encrypted_key = this._encrypt(key);
        const encrypted_data = this._encrypt(data);
        sessionStorage.setItem(encrypted_key,encrypted_data)
    }
    get(key){
        const encrypted_key = this._encrypt(key);
        return this._decrypt(sessionStorage.getItem(encrypted_key))
    }
    getUserDetails() {
        return sessionStorage.getItem('USER_KEY')
    }

    remove(key){
        sessionStorage.removeItem(this._encrypt(key))
    }

    _encrypt(data){
        return data;
    }
    _decrypt(data){
        return data
    }
}
export default new StorageService()