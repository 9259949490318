import * as ActionTypes from "./actions";

const initialState = {
  all_capabilities: [],
  status: [
    { id: "", value: "" },
    { id: "0", value: "Active" },
    { id: "1", value: "Disabled" },
    { id: "2", value: "Draft" },
  ],
  status_map: {
    0: "Active",
    1: "Disabled",
    2: "Draft",
    3: "Assigned To Project",
    4: "Released From Project",
    6: "Open",
    7: "In Progress",
    8: "Completed",
    9: "Completed Approved",
  },
  interval: [
    { id: "", value: "" },
    { id: "00Q", value: "Quarterly" },
    { id: "00M", value: "Mid Year" },
    { id: "00A", value: "Annual" },
  ],
  interval_map: {
    "00Q": "Quarterly",
    "00M": "Mid Year",
    "00A": "Annual",
  },
  gaol_type: [
    { id: "", value: "" },
    { id: "Strategic", value: "Strategic" },
    { id: "Personal", value: "Personal" },
  ],
  gaol_type_map: {
    Strategic: "Strategic",
    Personal: "Personal",
  },
  assessment_types_map: {
    "00Q": "Quarterly",
    "00M": "Mid Year",
    "00A": "Annual",
    "00P": "Project",
    "00S": "Scheduled",
    EOP: "End of probation",
  },
  assessment_types: [
    { id: "", value: "" },
    { id: "00Q", value: "Quarterly" },
    { id: "00M", value: "Mid Year" },
    { id: "00A", value: "Annual" },
    { id: "00P", value: "Project" },
    { id: "00S", value: "Scheduled" },
    { id: "EOP", value: "End of probation" },
  ],
};

function fetchProjectRoles() {}

const app_reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_ALL_TRAINING:
      return {
        ...state,
        all_training_data: action.payload || [],
      };
    case ActionTypes.UPDATE_ALL_CAPABILITIES:
      return {
        ...state,
        all_capabilities: action.payload || [],
      };
    case ActionTypes.UPDATE_ACTIVE_CAPABILITES:
      return {
        ...state,
        all_active_capabilities: action.payload || [],
      };
    case ActionTypes.SELECT_TRAINING_FOR_UPDATE:
      return {
        ...state,
        selected_trainging: action.payload || {},
      };
    case ActionTypes.SELECT_GOAL_FOR_UPDATE:
      return {
        ...state,
        selected_goal: action.payload || {},
      };
    case ActionTypes.UPDATE_ALL_GOALS:
      return {
        ...state,
        all_goal_data: action.payload || [],
      };
    case ActionTypes.UPDATE_GOAL_MILESSTONE:
      return {
        ...state,
        all_goal_milestone: action.payload || [],
      };
    case ActionTypes.SELECT_GOAL_MILESSTONE_FOR_UPDATE:
      return {
        ...state,
        goal_milestone_for_update: action.payload || {},
      };
    case ActionTypes.UPDATE_EMPLOYEE_LIST:
      return {
        ...state,
        all_employee_list: action.payload || {},
      };
    case ActionTypes.UPDATE_EMPLOYEE_SELECTION_LIST:
      return {
        ...state,
        selected_items: action.payload || [],
      };
    case ActionTypes.SELECT_ASSESSEMENT_FOR_UPDATE:
      return {
        ...state,
        assessment_for_update: action.payload || {},
      };
    case ActionTypes.UPDATE_ASSESSEMENT_LIST:
      return {
        ...state,
        all_assessment_list: action.payload || {},
      };
    case ActionTypes.UPDATE_MANAGE_PROJECT_LIST:
      return {
        ...state,
        manage_project_list: action.payload || [],
      };
    case ActionTypes.UPDATE_ALL_POSITION:
      return {
        ...state,
        manage_position_list: action.payload || [],
      };
    case ActionTypes.UPDATE_NOTIFICATION_LIST:
      return {
        ...state,
        notification_list: action.payload || [],
      };
    case ActionTypes.EDIT_NOTIFICATION:
      return {
        ...state,
        notification_item: action.payload || [],
      };
    case ActionTypes.MY_REVIEW_ASSESSEMENT:
      return {
        ...state,
        my_review_assessemnt: action.payload || {},
      };
    default:
      return state;
  }
};

export default app_reducer;
