
import React, { Fragment } from 'react';
import { Row, Col, Container, Card, CardHeader, CardBody, Label, Input, FormGroup, Alert, Button } from 'reactstrap';
import ReactCaptchaGenerator from 'react-captcha-generator'
import { DropdownList } from 'react-widgets';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
// import AppHeader from "../../Layout/AppHeader/";
import { COUNTRY_LIST } from '../constants'
import * as UIHelper from '../../ui-components/ui_functions'
// import AuthenticationService from "../../service/AuthenticationService"
import LoginModal from "../../ui-components/login_model"
import { ORGANIAZTION_CREATE } from "../../../src/service/service_const";
// import {
//     Modal,ModalHeader, ModalBody

//   } from "reactstrap";

import AjaxService from '../../service/AjaxService'
// import { file } from '@babel/types';
// import { data } from 'jquery';

let fileData = []
class RegistrationPage extends React.Component {
    createSchema = Yup.object().shape({
        // file: Yup.mixed().required('Image is required'),
        organization: Yup.string().required('Organization name is required'),
        address: Yup.string().required('Address line 1 is required'),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
        country: Yup.string().required('Country is required'),
        zip: Yup.string().required('Zip Code is required').min(5, "Please enter valid zip code").max(10, "Please enter valid zip code").matches("^[0-9]+$", "Please enter valid zip code"),
        firstName: Yup.string().required('First name  is required').matches("^[a-zA-Z0-9 ]*$", "Special characters are not allowed in first name"),
        lastName: Yup.string().required('Last name is required').matches("^[a-zA-Z0-9 ]*$", "Special characters are not allowed in last name"),
        email: Yup.string().required('Email Address is required').matches(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            "Enter a valid email address"),
        phone: Yup.string().required("Primary contact number is required").matches("([+]?\\d[91]?[01]?)?(\\d{12})", "Enter a valid primary contact number with code +91 or +01"),
        secondaryContact: Yup.string().matches("([+]?\\d[91]?[01]?)?(\\d{12})", "Enter a valid secondary contact number with code +91 or +01"),
        password: Yup.string().required('Password is required').test('confirm-pwd-test', "Password is not matched", function (value) {
            return this.options.parent["confirmPassword"] === value
        }),
        confirmPassword: Yup.string().required('Please enter confirm password'),
        __confirmCaptcha: Yup.string().required('Please enter captcha').test('confirm-cpt-test', "Captch does not matched", function (value) {

            return this.options.parent["__captcha"] === value
        }),
        __captcha: Yup.string().required('Please enter captcha')
    });

    constructor(props) {
        super(props)
        this.state = {
            organization: "",
            address: "",
            // orgaddressline2: "",
            city: "",
            state: "",
            country: "",
            zip: "",
            firstName: "",
            middleName: "",
            lastName: "",
            email: "",
            phone: "+01",
            secondaryContact: "",
            password: "",
            confirmPassword: "",
            __captcha: "",
            __confirmCaptcha: "",
            __show_login_model: true,
            show: false,
            file:"", 
            response:""
        };
        this.onCaptchaGenerate = this.__onCaptchaGenerate.bind(this)
        this.register_organization = this.__register_organization.bind(this)
        this.onLoginClick = () => {
            //   alert();
            if (window.location.href == window.location.origin + "/#/") {
                window.location.href = window.location.origin + "/#/";

            }
            window.location.reload();
        }
        this.onRegister = this.__show_registration.bind(this)
    }

    __onCaptchaGenerate(text) {
        this.setState({ "__captcha": text })
    }

    __show_registration() {
        this.setState({ __show_login_model: !this.state.__show_login_model })
    }
    async __register_organization(data_to_post) {
        console.log(data_to_post)
       AjaxService.makePostRequest(ORGANIAZTION_CREATE, data_to_post).then((res) => {
            UIHelper.showSuccessMessage("Organization has been saved. Please check your email to Verify")
            this.fileUpload(fileData[0], res.result.orgId)
        }).catch((err) => {
            UIHelper.showErrorMessage("Organization fialed to save.");
        })
    }

    fileUpload = async (data, id) => {
        AjaxService.makePostFileUploade(`/organization/upload/${id}`, data)
        .then(res => {
            this.onLoginClick();
        })
        .catch(err => UIHelper.showErrorMessage("Organization fialed to save."))
        
    }

    dataValues = (data) => {
        let formData = new FormData()
        formData.append("file", data)
        console.log("data", data)
        fileData.push(data)
    }



    render() {
        return (this.props.show === true && <Fragment>
            {this.state.__show_login_model === true ? <LoginModal show={this.props.show} onRegister={this.onRegister}></LoginModal> : <Container fluid>
                {/* <Row>
                    <Col sm={12}>
                        <AppHeader></AppHeader>
                    </Col>
                </Row> */}
                <Row>
                    <Col sm={12} lg="12" >
                        <Card className="m-3">
                            <CardHeader>Registration Organization
                                <div className="btn-actions-pane-right">
                                    <Button color="primary" className="mr-2" onClick={this.onLoginClick}>Login</Button>
                                    {/* <Button color="success" onClick={this.register_organization(this.createSchema)}>Register organization</Button> */}
                                </div>
                            </CardHeader>
                        </Card>
                        <Formik enableReinitialize={true} initialValues={this.state}
                         validationSchema={this.createSchema}
                            onSubmit={data => {
                                let values = { ...data }
                                for (let key in values) {
                                    if (key.startsWith("__"))
                                        delete values[key]
                                }

                                if (typeof this.register_organization === 'function') {
                                    this.register_organization(values)
                                }
                            }}
                        >
                            {
                                ({ errors, touched, setFieldValue }) => (
                                    <Form>
                                        <Card className="m-3">
                                            <CardHeader>Organization Contact Details
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col sm={12} lg={4}>
                                                        <FormGroup className="input_form">
                                                            <Label for="organization"> Name</Label>
                                                            <Input type="text" name="organization" id="__registration_org_name" value={this.state.organization || ''} readOnly={this.state.__readonly === true} autoComplete="off" onChange={(evt) => { this.setState({ organization: evt.target.value }); setFieldValue('organization', evt.target.value) }} />
                                                            {errors.organization && touched.organization ? <Alert color="danger">{errors.organization}</Alert> : null}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={12} lg={4}>
                                                        <FormGroup className="input_form">
                                                            <Label for="address">Address Line 1</Label>
                                                            <Input type="text" name="address" id="__registration_orgaddressline1" value={this.state.address || ''} readOnly={this.state.__readonly === true} autoComplete="off" onChange={(evt) => { this.setState({ address: evt.target.value }); setFieldValue('address', evt.target.value) }} />
                                                            {errors.address && touched.address ? <Alert color="danger">{errors.address}</Alert> : null}
                                                        </FormGroup>
                                                    </Col>
                                                    {/* <Col sm={12} lg={4}>
                                                        <FormGroup>
                                                            <Label for="orgaddressline2">Address Line 2</Label>
                                                            <Input type="text" name="orgaddressline2" id="__registration_orgaddressline2" value={this.state.orgaddressline2 || ''} readOnly={this.state.__readonly === true} autoComplete="off" onChange={(evt) => { this.setState({ orgaddressline2: evt.target.value }); setFieldValue('orgaddressline2', evt.target.value) }} />
                                                            {errors.orgaddressline2 && touched.orgaddressline2 ? <Alert color="danger">{errors.orgaddressline2}</Alert> : null}
                                                        </FormGroup>
                                                    </Col> */}
                                                    <Col sm={12} lg={4}>
                                                        <FormGroup className="input_form">
                                                            <Label for="city">City</Label>
                                                            <Input type="text" name="city" id="__registration_orgcity" value={this.state.city || ''} readOnly={this.state.__readonly === true} autoComplete="off" onChange={(evt) => { this.setState({ city: evt.target.value }); setFieldValue('city', evt.target.value) }} />
                                                            {errors.city && touched.city ? <Alert color="danger">{errors.city}</Alert> : null}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={12} lg={4}>
                                                        <FormGroup className="input_form">
                                                            <Label for="state">State</Label>
                                                            <Input type="text" name="state" id="__registration_orgstate" value={this.state.state || ''} readOnly={this.state.__readonly === true} autoComplete="off" onChange={(evt) => { this.setState({ state: evt.target.value }); setFieldValue('state', evt.target.value) }} />
                                                            {errors.state && touched.state ? <Alert color="danger">{errors.state}</Alert> : null}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={12} lg={4}>
                                                        <FormGroup className="input_form">
                                                            <Label for="country">Country</Label>

                                                            <DropdownList data={COUNTRY_LIST} value={this.state.country}
                                                                onChange={(value) => {
                                                                    this.setState({ country: value })
                                                                    setFieldValue("country", value)
                                                                }}
                                                            >
                                                            </DropdownList>
                                                            {errors.country && touched.country ? <Alert color="danger">{errors.country}</Alert> : null}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={12} lg={4}>
                                                        <FormGroup className="input_form">
                                                            <Label for="zip">Zip</Label>
                                                            <Input type="text" name="zip" id="__registration_orgzip" value={this.state.zip || ''} readOnly={this.state.__readonly === true} autoComplete="off" onChange={(evt) => { this.setState({ zip: evt.target.value }); setFieldValue('zip', evt.target.value) }} />
                                                            {errors.zip && touched.zip ? <Alert color="danger">{errors.zip}</Alert> : null}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                        <Card className="m-3">
                                            <CardHeader>Organization Contact Details
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    {/* <Col sm={12} lg={12}>
                                                        <FormGroup>
                                                            <Label for="firstName"><b>Admin User Details</b></Label>
                                                        </FormGroup>
                                                    </Col> */}
                                                    <Col sm={12} lg={4}>
                                                        <FormGroup className="input_form">
                                                            <Label for="firstName"> First Name</Label>
                                                            <Input type="text" name="firstName" id="__registration_firstName" value={this.state.firstName || ''} readOnly={this.state.__readonly === true} autoComplete="off" onChange={(evt) => { this.setState({ firstName: evt.target.value }); setFieldValue('firstName', evt.target.value) }} />
                                                            {errors.firstName && touched.firstName ? <Alert color="danger">{errors.firstName}</Alert> : null}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={12} lg={4}>
                                                        <FormGroup className="input_form">
                                                            <Label for="middleName"> Middle Name</Label>
                                                            <Input type="text" name="middleName" id="__registration_middleName" value={this.state.middleName || ''} readOnly={this.state.__readonly === true} autoComplete="off" onChange={(evt) => { this.setState({ middleName: evt.target.value }); setFieldValue('middleName', evt.target.value) }} />
                                                            {errors.middleName && touched.middleName ? <Alert color="danger">{errors.middleName}</Alert> : null}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={12} lg={4}>
                                                        <FormGroup className="input_form">
                                                            <Label for="lastName"> Last Name</Label>
                                                            <Input type="text" name="lastName" id="__registration_lastName" value={this.state.lastName || ''} readOnly={this.state.__readonly === true} autoComplete="off" onChange={(evt) => { this.setState({ lastName: evt.target.value }); setFieldValue('lastName', evt.target.value) }} />
                                                            {errors.lastName && touched.lastName ? <Alert color="danger">{errors.lastName}</Alert> : null}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12} lg={4}>
                                                        <FormGroup className="input_form">
                                                            <Label for="email">Email Address</Label>
                                                            <Input type="text" name="email" id="__registration_emailAddress" value={this.state.email || ''} readOnly={this.state.__readonly === true} autoComplete="off" onChange={(evt) => { this.setState({ email: evt.target.value }); setFieldValue('email', evt.target.value) }} />
                                                            {errors.email && touched.email ? <Alert color="danger">{errors.email}</Alert> : null}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={12} lg={4}>
                                                        <FormGroup className="input_form">
                                                            <Label for="phone">Primary contact number</Label>
                                                            <Input type="text" name="phone" id="__registration_primaryContact" value={ this.state.phone || ''} readOnly={this.state.__readonly === true} autoComplete="off" onChange={(evt) => { this.setState({ phone: evt.target.value }); setFieldValue('phone', evt.target.value) }} />
                                                            {errors.phone && touched.phone ? <Alert color="danger">{errors.phone}</Alert> : null}
                                                        </FormGroup>
                                                    </Col>
                                                    {/* <Col sm={12} lg={4}>
                                                        <FormGroup>
                                                            <Label for="secondaryContact">Secondary  contact number</Label>
                                                            <Input type="text" name="secondaryContact" id="__registration_secondaryContact" value={this.state.secondaryContact || ''} readOnly={this.state.__readonly === true} autoComplete="off" onChange={(evt) => { this.setState({ secondaryContact: evt.target.value }); setFieldValue('secondaryContact', evt.target.value) }} />
                                                            {errors.secondaryContact && touched.secondaryContact ? <Alert color="danger">{errors.secondaryContact}</Alert> : null}
                                                        </FormGroup>
                                                    </Col> */}
                                                    <Col sm={12} lg={4}>
                                                        <FormGroup className="input_form">
                                                            <Label for="password">Password</Label>
                                                            <Input type="password" name="password" id="__registration_password" value={this.state.password || ''} readOnly={this.state.__readonly === true} autoComplete="off" onChange={(evt) => { this.setState({ password: evt.target.value }); setFieldValue('password', evt.target.value) }} />
                                                            {errors.password && touched.password ? <Alert color="danger">{errors.password}</Alert> : null}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={12} lg={4}>
                                                        <FormGroup className="input_form">
                                                            <Label for="confirmPassword">Confirm Password</Label>
                                                            <Input type="password" name="confirmPassword" id="__registration_confirmPassword" value={this.state.confirmPassword || ''} readOnly={this.state.__readonly === true} autoComplete="off" onChange={(evt) => { this.setState({ confirmPassword: evt.target.value }); setFieldValue('confirmPassword', evt.target.value) }} />
                                                            {errors.confirmPassword && touched.confirmPassword ? <Alert color="danger">{errors.confirmPassword}</Alert> : null}
                                                        </FormGroup>
                                                    </Col>

                                                    <Col sm={12} lg={4}>
                                                        <FormGroup className="input_form">
                                                            <Label for="__confirmCaptcha">Captcha</Label>
                                                            <Input type="text" name="__confirmCaptcha" id="__registration___confirmCaptcha" value={this.state.__confirmCaptcha || ''} readOnly={this.state.__readonly === true} autoComplete="off" onChange={(evt) => { this.setState({ __confirmCaptcha: evt.target.value }); setFieldValue('__confirmCaptcha', evt.target.value) }} />
                                                            {errors.__confirmCaptcha && touched.__confirmCaptcha ? <Alert color="danger">{errors.__confirmCaptcha}</Alert> : null}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={12} lg={1}>
                                                        <FormGroup className="input_form">
                                                            <ReactCaptchaGenerator result={this.onCaptchaGenerate}></ReactCaptchaGenerator>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={12} lg={3}>
                                                        <FormGroup className="input_form">
                                                            <Label for="file">Logo upload</Label>
                                                            <Input id="__registration___confirmCaptcha"  name="file" type="file"
                                                            readOnly={this.state.__readonly === true}
                                                            value={this.state.file.name} 
                                                            onChange={(event, selected) => {
                                                                //  console.log(selected)
                                                                // this.setState({ file: event.target.files[0]});
                                                                // var formData = new FormData();
                                                                // formData.append("file",event.target.files[0])
                                                                this.dataValues(event.target.files[0])
                                                                setFieldValue('file', event.target.files[0])
                                                            }} 
                                                        />
                                                            {errors.file && touched.file ? <Alert color="danger">{errors.file}</Alert> : null}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12} lg={2}>
                                                        <Button color="success">Register organization</Button>
                                                    </Col>
                                                </Row>
                                            </CardBody></Card>
                                    </Form>
                                )
                            }
                        </Formik>
                    </Col>

                </Row>
            </Container>}
        </Fragment>
      )
    }

}
export default RegistrationPage