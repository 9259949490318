import React from 'react';
import Loader from 'react-loaders';
class AjaxLoader extends React.Component{
    render(){
        return (
            <div className="loader-container">
               
            <div className="loader-container-inner">
                <div className="text-center">
                    <Loader type="line-scale"/>
                </div>
                <h6 className="mt-3 text-center">
                    {this.props.loadingText || "Loading ....."}
                </h6>
            </div>
        </div>
        )
    }
}
export default AjaxLoader