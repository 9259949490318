import { createStore, combineReducers } from "redux";
import reducers from "../reducers";
import AppState from "../store/reducers/app_reducer";

const STRIPE_PUBLIC =
  "pk_test_51JZxBWILCS3yoNNWMql1pQ7hlMZulfsDr9NaCWCSXdSfLvVz9bxeFOn0xTrIagQh0a69U2lmumkVB2fYT1j3fAb700Ncf7zfXT";

export default function configureStore() {
  return createStore(
    combineReducers({
      ...reducers,
      AppState,
    }),
    {}
  );
}

export { STRIPE_PUBLIC };
