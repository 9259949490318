// OAUTH RELATED URLS
// export const AUTHENTICATION_URL = "https://pulseoftheworkforce.com/services/oauth/login" //"https://pulseoftheworkforce.com/oauth2/authorize";
// export const AUTHERIZATION_URL = "https://pulseoftheworkforce.com/services/oauth/accessToken"//"https://pulseoftheworkforce.com/oauth2/token";
// export const LOGOUT_URL = "https://pulseoftheworkforce.com/services/oauth/logout"
// export const USER_INFO_URL = "https://pulseoftheworkforce.com/oauth2/userinfo";
// export const OAUTH_REDIRECT_URL = "https://pulseoftheworkforce.com/"

//OAUTH RELATED URLS
export const AUTHENTICATION_URL = "http://localhost:8080/oauth/login"; //"https://pulseoftheworkforce.com/oauth2/authorize";
export const AUTHERIZATION_URL = "http://localhost:8080/oauth/accessToken"; //"https://pulseoftheworkforce.com/oauth2/token";
export const LOGOUT_URL =
  "https://pulseoftheworkforce.com/services/oauth/logout";
export const USER_INFO_URL = "https://pulseoftheworkforce.com/oauth2/userinfo";
export const OAUTH_REDIRECT_URL = "http://localhost:3000/";

// ALL THE URLS WHICH ARE USED BY UI
// export const BASE_URL = "http://localhost:8080";

// ALL THE URLS WHICH ARE USED BY UI
export const BASE_URL = "https://pulseoftheworkforce.com/services";
export const BASE_URL2 = "https://pulseoftheworkforce.com";
export const LOGIN_URL = "/users/signin";
export const ORGANIAZTION_CREATE = "/organization/create";
export const POSITION_LIST_URL = "/role/all";
export const DASHBOARD_TASK_URL = "/Employee/dashboard/tasks";
export const DASHBOARD_REPORTEE_URL = "/Employee/dashboard/reportee";
export const DASHBOARD_NOTIFICATION_URL = "/Employee/dashboard/notifications";
export const CONTENT_CREATE_URL = "/ContentManagment/create";
export const CONTENT_MANAGE_LIST_URL = "/ContentManagment/getAllContents";
export const CONTENT_PUBLISH_URL = "/ContentManagment/publishContent";
export const CONTENT_UPDATE_URL = "/ContentManagment/updateContent";
export const CONTENT_DELETE_URL = "/ContentManagment/deleteContent";
export const EMPLOYEE_SEARCH = "/Employee/all";
export const EMPLOYEE_PREVILAGE_URL = "/Employee/getEmplpoyeeAccessRoles";
export const PREVILAGE_URL = "/UserAccessRoles/";
export const EMPLOYEE_GRANT_PREVILAGE_URL =
  "/Employee/createEmployeeAccessRole";
export const CAPABILITIES_LIST_URL = "mastercapability/all";
export const EMPLOYEE_CREATE_TIMESHEET = "/Employee/createTimeSheet";

// all roles are defined here
export const ROLE_MY_ASSESSMENT = "MY_ASSESSMENT";
export const ROLE_REVIEW_ASSESSMENT = "REVIEW_ASSESSMENT";
export const ROLE_MY_PROJECT = "MY_PROJECT";
export const ROLE_MY_GOALS = "MY_GOALS";
export const ROLE_MY_TRAININGS = "MY_TRAININGS";
export const ROLE_MY_SKILLS = "MY_SKILLS";

export const ROLE_MANAGE_EMPLOYEE = "MANAGE_EMPLOYEE";
export const ROLE_MANAGE_GOALS = "MANAGE_GOALS";
export const ROLE_MANAGE_TRAINING = "MANAGE_TRAINING";
export const ROLE_MANAGE_PROJECT = "MANAGE_PROJECT";
export const ROLE_MANAGE_POSITION = "MANAGE_POSITION";
export const ROLE_MANAGE_ASSESSMENT = "MANAGE_ASSESSMENT";
export const ROLE_MANAGE_CAPABILITY = "MANAGE_CAPABILITY";
export const ROLE_MANAGE_CONTENT = "MANAGE_CONTENT";
export const MANAGE_TIMESHEET = "MANAGE_TIMESHEET";
export const MY_TIMESHEET = "MY_TIMESHEET";
